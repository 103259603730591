import classNames from "classnames/bind";
import styles from "./DevTimmy.module.scss";
import { TestTable } from "./TestTable/TestTable";
import { TestUploader } from "./TestUploader/TestUploader";
import { TestDownloadFile } from "./TestDownloadFile/TestDownloadFile";

const cx = classNames.bind(styles);

export const DevTimmy = () => {
   return (
      <div className={cx("container")}>
         {/* <TestTable /> */}
         {/* <TestUploader /> */}
         <TestDownloadFile />
      </div>
   );
};

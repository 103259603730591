import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useConvoContext } from "../../../../contexts/ConvoContext";
import { useSearchQueryParams_PVi } from "../../../react/useSearchQueryParams";
import useDebounce from "../../../react/useDebounce";

export const useQConvo_GetLeadsThreadsInProperty = () => {
   const { sosAxios } = useSosAxios();

   const { convoState } = useConvoContext();

   const { q, tags } = convoState;

   const [pid] = useSearchQueryParams_PVi().pid;

   const dbQ = useDebounce(q, 500);

   const baseParams = {
      q: dbQ,
      tags: tags,
   };

   const query = useQuery({
      queryKey: [`useQConvo_GetLeadsThreadsInProperty`, pid, baseParams],
      staleTime: 500,
      enabled: !!pid,
      queryFn: () =>
         sosAxios
            .get(`/conversations/threads/leads-threads-in-property/${pid}`, { params: { ...baseParams } })
            .then(({ data }: { data: Conversations_GetLeadsThreadsInProperty_Response }) => data),
   });
   return {
      ...query,
   };
};

export class Conversations_GetLeadsThreadsInProperty_Thread {
   tid!: number;
}

export class Conversations_GetLeadsThreadsInProperty_Lead_Property {
   pid!: string;
   address_one_line!: string;
}

export class Conversations_GetLeadsThreadsInProperty_Lead_Offer {
   oid!: number;
   status!: string;
}

export class Conversations_GetLeadsThreadsInProperty_Lead {
   lid!: number;
   status!: string;
   threads!: Conversations_GetLeadsThreadsInProperty_Thread[];
   properties!: Conversations_GetLeadsThreadsInProperty_Lead_Property[];
   offers!: Conversations_GetLeadsThreadsInProperty_Lead_Offer[];
}

export class Conversations_GetLeadsThreadsInProperty_Response {
   leads!: Conversations_GetLeadsThreadsInProperty_Lead[];
}

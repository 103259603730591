import classNames from "classnames/bind";
import { SHrPopup, SHrSosButton, SHrSpinner } from "@simplyhomes/react";

import styles from "./SoSConvoThreadCard.module.scss";
import { SHu_js_timeSince } from "@simplyhomes/utils";
import { TUseQCon_getThreadsByPropertyId } from "../../../hooks/conversation-api/queries/threads/useQConvo_Threads";
import { useSoSOptions_Convo_Thread_Tags } from "../../../hooks/queries/options/useQSoS_Options";
import { useMConvo_ToggleThreadSubscription } from "../../../hooks/conversation-api/mutates/threads/useMConvo_ToggleThreadSubscription";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { handleString, SoSConvoPostCommentModal } from "../SoSConvoPostCommentModal/SoSConvoPostCommentModal";
import { useSearchQueryParams_Convo } from "../../../hooks/react/useSearchQueryParams";
import { useState } from "react";
import { useClickOutside } from "../../../hooks/react/useClickOutside";
import { SoSCopyToClipboard } from "../../../components/common/SoSCopyToClipboard/SoSCopyToClipboard";
import { useMConvo_DeleteThread } from "../../../hooks/conversation-api/mutates/threads/useMConvo_DeleteThread";
import { SoSConfirmWrapper } from "../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { useConvoContext } from "../../../contexts/ConvoContext";

const cx = classNames.bind(styles);

type TPViThreadCardProps = {
   thread: TUseQCon_getThreadsByPropertyId;
   type: "property" | "lead" | "transaction";
   isLeadThread?: boolean;
   lid?: number;
   pid?: string;
};

export const SoSConvoThreadCard = ({ thread, type, isLeadThread, lid, pid }: TPViThreadCardProps) => {
   const { convoDispatch } = useConvoContext();

   const [dropdownVisible, setDropdownVisible] = useState(false);

   const [isOpenPostCommentModal, setIsOpenPostCommentModal] = useState(false);

   const { ref } = useClickOutside({
      onClickOutside: () => {
         setDropdownVisible(false);
      },
   });

   const [threadId, setThreadId] = useSearchQueryParams_Convo().tid;

   const qGetConvoTags = useSoSOptions_Convo_Thread_Tags();

   const getConvoTagOptions = qGetConvoTags.data?.options;

   const { mutate, isPending } = useMConvo_ToggleThreadSubscription({ type });

   const { mutate: mutateDeleteThread, isPending: isPendingDeleteThread } = useMConvo_DeleteThread({
      type,
      lid,
      pid,
   });

   const { tid, title, content, comment_count, creator, tags, created_at, subscribed } = thread;

   const convoTagsLabel = tags?.map((value) => {
      const option = getConvoTagOptions?.find((o) => o.value === value);
      return option ? option.label : null;
   });
   const handleShowThread = () => {
      setThreadId(tid.toString());
      setIsOpenPostCommentModal(!isOpenPostCommentModal);
   };
   const handleSubscribe = () => mutate({ tid: thread.tid, subscribed: !subscribed });

   const timestamp = new Date(created_at).getTime();

   const urlThread =
      type === "property"
         ? `${window.location.origin}${window.location.pathname}?pvi_pid=${pid}&convo_tid=${tid.toString()}`
         : type === "lead"
         ? `${window.location.origin}${window.location.pathname}?offers_lid=${lid}&convo_tid=${tid.toString()}`
         : "";

   return (
      <div className={cx("threadCardC", { bgWhite: isLeadThread })}>
         <div className={cx("personC")}>
            <div className={cx("avt")}>
               <img src={creator.avatar || creator.picture} alt="" />
            </div>
            <div className={cx("nameC")}>
               <span>
                  <b>{creator.name}</b>
               </span>
               <span className={cx("role")}>{creator.roles}</span>
            </div>
            <span className={cx("time")}>{SHu_js_timeSince(timestamp)}</span>
            <div>
               <SHrSosButton
                  type="text"
                  loading={isPending}
                  buttonProps={{ className: cx("btn"), onClick: handleSubscribe }}
               >
                  {subscribed ? "Unsubscribe" : "Subscribe"}
               </SHrSosButton>
            </div>
            <button
               title="menu"
               type="button"
               className={cx("img-icon")}
               onClick={() => setDropdownVisible(!dropdownVisible)}
            >
               <img src={CF_CDN_URL("/assets/property_viability/more_horiz_neutral500.svg")} alt="" />
            </button>

            <div className={cx("dropdownC", { dropdownVisible })} ref={ref}>
               <div className={cx("dropdown-menu")}>
                  <SHrSosButton
                     type="text"
                     buttonProps={{
                        className: cx("dropdown-item"),
                        onClick: () => {
                           setThreadId(tid.toString());
                           setDropdownVisible(false);
                           convoDispatch({ overwrite: { isOpenThreadEditor: true } });
                        },
                     }}
                  >
                     {isPending ? (
                        <div className={cx("loading")}>
                           <SHrSpinner />
                        </div>
                     ) : (
                        <>
                           <img src={CF_CDN_URL("/assets/edit_black.svg")} alt="" />
                           <span>Edit thread</span>
                        </>
                     )}
                  </SHrSosButton>
                  <SHrSosButton type="text" buttonProps={{ className: cx("dropdown-item"), onClick: () => {} }}>
                     {isPending ? (
                        <div className={cx("loading")}>
                           <SHrSpinner />
                        </div>
                     ) : (
                        <>
                           <img src={CF_CDN_URL("/assets/insert_link_black.svg")} alt="" />
                           <SoSCopyToClipboard value={urlThread} placement="left" type="text" title="Copy link" />
                        </>
                     )}
                  </SHrSosButton>
                  <SoSConfirmWrapper
                     title="Are you sure you want to delete this thread?"
                     content="This action allows to delete this thread."
                     type="default"
                     onConfirm={() => {
                        mutateDeleteThread({ tid });
                        setDropdownVisible(false);
                     }}
                  >
                     <SHrSosButton
                        type="text"
                        buttonProps={{
                           className: cx("dropdown-item", "delete"),
                        }}
                     >
                        {isPendingDeleteThread ? (
                           <div className={cx("loading")}>
                              <SHrSpinner />
                           </div>
                        ) : (
                           <>
                              <img src={CF_CDN_URL("/assets/delete_outline_black.svg")} alt="" />
                              <span>Delete</span>
                           </>
                        )}
                     </SHrSosButton>
                  </SoSConfirmWrapper>
               </div>
            </div>
         </div>
         <div className={cx("contentC")} onClick={handleShowThread}>
            <span className={cx("title")}>{title}</span>
            <p>{handleString(content)}</p>

            {convoTagsLabel.length > 0 && (
               <div className={cx("keywordC")}>
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/property_viability/local_offer_black.svg")} alt="" />
                  </div>

                  <div className={cx("keywordGroupC")}>
                     {convoTagsLabel.map((tag, i) => (
                        <div className={cx("keyword")} key={i}>
                           {tag}
                        </div>
                     ))}
                  </div>
               </div>
            )}
         </div>
         <div className={cx("commentC")}>
            <span className={cx("numberComs")}>
               {comment_count} {comment_count > 1 ? "comments" : "comment"}
            </span>
            <div className={cx("dot")}></div>
            <div className={cx("postCom")} onClick={handleShowThread}>
               Post a comment
            </div>
         </div>

         <SHrPopup show={!!threadId} handleShow={(b) => !b && setThreadId("")}>
            <SoSConvoPostCommentModal type={type} pid={pid} lid={lid} />
         </SHrPopup>
      </div>
   );
};

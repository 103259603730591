import classNames from "classnames/bind";
import styles from "./PViFilteringAndSortingModal.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { SHrSosButton } from "@simplyhomes/react";
import { SoSConfirmWrapper } from "../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import SoSVerticalMenu, { TMenuItem } from "../../../components/SoSVerticalMenu/SoSVerticalMenu";
import { useCallback, useMemo, useState } from "react";
import { PViFiltering } from "./PViFiltering/PViFiltering";
import { PViSorting } from "./PViSorting/PViSorting";
import { useSoSOptions_PVi_FilterSortTemplates } from "../../../hooks/queries/options/useQSoS_Options";
import { SoSInputSelect } from "../../../components/common/SoSInputSelect/SoSInputSelect";
import { useMPVi_CreateFilterSortTemplate } from "../../../hooks/property-viability/mutates/filterSortTemplates/useMPVi_CreateFilterSortTemplate";
import { usePViContext } from "../../../contexts/PViContext";
import { useMPVi_UpdateFilterSortTemplate } from "../../../hooks/property-viability/mutates/filterSortTemplates/useMPVi_UpdateFilterSortTemplate";
import { usePViQueryParams } from "../../../hooks/property-viability/hooks/usePViQueryParams";
import { useQPVi_FilterSortTemplateById } from "../../../hooks/property-viability/queries/useQPVi_FilterSortTemplateById";

const cx = classNames.bind(styles);

const menu: TMenuItem[] = [
   {
      title: "Filter",
      type: "filter",
      icon: "property_viability/tune_black.svg",
   },
   {
      title: "Sort By",
      type: "sort",
      icon: "property_viability/sort_black.svg",
   },
];

export const PViFilteringAndSortingModal = ({ onClose }: PViFilteringAndSortingModalProps) => {
   const qpPVi = usePViQueryParams();

   const { pviState, pviOverwrite, pviDispatch } = usePViContext();

   const { filterSortTemplateData, fstid, filterSortTemplateName } = pviState;

   const { filter, sort } = filterSortTemplateData;

   const [selectedMenuItem, setSelectedMenuItem] = useState<string>("filter");

   const {
      setPropertyTypes,
      setZips,
      setStates,
      setMarkets,
      setSources,
      setHasLead,
      setSort,
      setMinSourcePrice,
      setMaxSourcePrice,
      setOfferStatuses,
   } = qpPVi;

   const [isFilterNameInvalid, setIsFilterNameInvalid] = useState<boolean>(false);

   const [isShowOverrideTemplateButton, setIsShowOverrideTemplateButton] = useState<boolean>(false);

   const { data } = useSoSOptions_PVi_FilterSortTemplates();

   const filterSortTemplateOptions = useMemo(() => {
      return data?.options || [];
   }, [data]);

   useQPVi_FilterSortTemplateById();

   const { mutate: mutateCreateFiterSortTemplate, isPending: isPendingCreateMutateFilterSortTemplate } =
      useMPVi_CreateFilterSortTemplate();

   const { mutate: mutateUpdateFilterSortTemplate, isPending: isPendingUpdateFilterSortTemplate } =
      useMPVi_UpdateFilterSortTemplate();

   const checkTemplateIsExisting = useCallback(
      (name: string) => !!filterSortTemplateOptions.find((t) => t.label === name),
      [filterSortTemplateOptions]
   );

   const handleCreateTemplate = (name: string) => {
      if (!name) return;

      if (isShowOverrideTemplateButton) return;

      mutateCreateFiterSortTemplate({ name });
   };

   const handleApplySavedTemplate = () => {
      setStates(filter?.states || []);
      setMarkets(filter?.markets || []);
      setZips(filter?.zips || []);
      setPropertyTypes(filter?.propertyTypes || []);
      setSources(filter?.sources || []);
      setHasLead(filter?.hasLead !== undefined ? String(filter.hasLead) : "");
      setSort(sort || "");
      setMinSourcePrice(filter?.minSourcePrice || "");
      setMaxSourcePrice(filter?.maxSourcePrice || "");
      setOfferStatuses(filter?.offerStatuses || []);
      onClose();
   };

   const handleSaveTemplate = () => {
      if (!fstid) {
         setIsFilterNameInvalid(true);
         return;
      }
      mutateUpdateFilterSortTemplate({
         name: filterSortTemplateName,
         data: filterSortTemplateData,
      });
   };

   return (
      <div className={cx("container")}>
         <div className={cx("headingC")}>
            <div className={cx("left")}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_viability/tune_black.svg")} alt="" />
               </div>
               <span>Filtering & Sorting</span>
            </div>
            <div className={cx("right")}>
               <SHrSosButton
                  type="text"
                  buttonProps={{
                     className: cx("resetBtn"),
                     onClick: () => pviDispatch({ type: "PVI_RESET_FILTER_SORT" }),
                  }}
               >
                  Reset
               </SHrSosButton>
               <button type="button" title="close" onClick={onClose} className={cx("closeBtn")}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>
         <div className={cx("bodyC")}>
            <div className={cx("searchTemplateC")}>
               <span>
                  <b>Apply a saved template</b>
               </span>
               <div className={cx("searchTemplate")}>
                  <SoSInputSelect
                     isLoading={isPendingCreateMutateFilterSortTemplate}
                     isMulti={false}
                     value={filterSortTemplateName}
                     onChange={(vs) => {
                        setIsFilterNameInvalid(false);
                        pviOverwrite({
                           fstid: +vs.value,
                           filterSortTemplateName: vs.label,
                        });
                     }}
                     onInputChange={(v) => {
                        const templateExisting = checkTemplateIsExisting(v);
                        setIsShowOverrideTemplateButton(templateExisting);
                     }}
                     options={filterSortTemplateOptions}
                     allowCreate={{ cb: ({ value }) => handleCreateTemplate(value) }}
                  />

                  {isFilterNameInvalid && <span className={cx("error")}>Please enter a name</span>}
               </div>
            </div>
            <div className={cx("filterSortC")}>
               <div className={cx("menuC")}>
                  <SoSVerticalMenu menu={menu} onClick={setSelectedMenuItem} />
               </div>
               {selectedMenuItem === "filter" && <PViFiltering />}
               {selectedMenuItem === "sort" && <PViSorting />}
            </div>
         </div>
         <div className={cx("footerBtn")}>
            <SoSConfirmWrapper
               title="Are you sure you want to create template?"
               content="This action allows to create template."
               type="default"
               onConfirm={handleSaveTemplate}
            >
               <SHrSosButton
                  type="tonal"
                  loading={isPendingUpdateFilterSortTemplate}
                  buttonProps={{
                     className: cx("btn", {
                        isOverrideButton: isShowOverrideTemplateButton,
                     }),
                  }}
               >
                  {isShowOverrideTemplateButton ? "Override Template" : "Save Template"}
               </SHrSosButton>
            </SoSConfirmWrapper>

            <SoSConfirmWrapper
               title="Are you sure you want to apply this template?"
               content="This action allows to apply this template."
               type="default"
               onConfirm={handleApplySavedTemplate}
            >
               <div className={cx("btnSave")}>
                  <SHrSosButton
                     buttonProps={{
                        className: cx("btn"),
                     }}
                  >
                     Apply Now
                  </SHrSosButton>
               </div>
            </SoSConfirmWrapper>
         </div>
      </div>
   );
};

type PViFilteringAndSortingModalProps = {
   onClose: () => void;
};

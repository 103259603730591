import { useQuery } from "@tanstack/react-query";
import { useConvoContext } from "../../../../contexts/ConvoContext";
import { useSosAxios } from "../../../axios/useSosAxios";
import useDebounce from "../../../react/useDebounce";
import { useSearchQueryParams_Offers } from "../../../react/useSearchQueryParams";

export const useQConvo_GetPropertiesThreadsInLead = () => {
   const { sosAxios } = useSosAxios();

   const { convoState } = useConvoContext();

   const { q, tags } = convoState;

   const [lid] = useSearchQueryParams_Offers().lid;

   const dbQ = useDebounce(q, 500);

   const baseParams = {
      q: dbQ,
      tags: tags,
   };

   const query = useQuery({
      queryKey: [`useQConvo_GetLeadsThreadsInProperty`, lid, baseParams],
      staleTime: 500,
      enabled: !!lid,
      queryFn: () =>
         sosAxios
            .get(`/conversations/threads/properties-threads-in-lead/${lid}`, { params: { ...baseParams } })
            .then(({ data }: { data: Conversations_GetPropertiesThreadsInLead_Response }) => data),
   });
   return {
      ...query,
   };
};

export class Conversations_GetPropertiesThreadsInLead_Thread {
   tid!: number;
}

export class Conversations_GetPropertiesThreadsInLead_Property {
   pid!: string;
   address_one_line!: string;
   threads!: Conversations_GetPropertiesThreadsInLead_Thread[];
}

export class Conversations_GetPropertiesThreadsInLead_Response {
   properties!: Conversations_GetPropertiesThreadsInLead_Property[];
}

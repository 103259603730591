import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { TPgSoS_SoSUnits_Schema_UtilitiesConfig } from "@simplyhomes/utils/dist/types/pg/sos/units";
import { TPgSoS_SoSProperties_Schema } from "@simplyhomes/utils";
import { PgSoS_SoSProperties_Rentometer_v1_summary_Data } from "@simplyhomes/utils/dist/types/pg/sos/properties";
import { TPgSoS_SoSUtilitiesAllowances_Schema_Data } from "@simplyhomes/utils/dist/types/pg/sos/utilities-allowances";
import { useWebSocket_CatchEvent } from "../../webSocket/useWebSocket_CatchEvent";
import { useTransactionsContext } from "../../../contexts/TransactionsContext";
import { useSearchQueryParams_PVi } from "../../react/useSearchQueryParams";
import { usePViContext } from "../../../contexts/PViContext";

export const useQPVi_Property = () => {
   const { sosAxios } = useSosAxios({ redirectOnError: false });
   const { pviOverwrite } = usePViContext();
   const [pid] = useSearchQueryParams_PVi().pid;

   const {
      transactionsState: { pidSelected },
   } = useTransactionsContext();

   const PID = pid || pidSelected;

   const query = useQuery({
      enabled: !!PID,
      queryKey: [`useQPVi_Property`, PID],
      queryFn: () =>
         sosAxios.get(`/property-viability/properties/${PID}`).then<PVi_GetPropertyById_Response>(({ data }) => {
            pviOverwrite({ sid: data.property.source.sid });
            return data;
         }),
   });

   useWebSocket_CatchEvent({ room: "refresh:PVi:Property:", id: PID }, () => query.refetch());

   return query;
};

export class PVi_GetPropertyById_Source {
   sid!: number;
   created_at!: string;
   source!: string;
}

type PVi_GetPropertyById_Property_Utilities_Allowances = TPgSoS_SoSUtilitiesAllowances_Schema_Data;

class PVi_GetPropertyById_Property_Approved_User {
   uid!: number;
   name!: string;
}

export class PVi_GetPropertyById_Lead_Thread {
   tid!: number;
}

export class PVi_GetPropertyById_Lead_Property {
   pid!: string;
   address_one_line!: string;
}

export class PVi_GetPropertyById_Lead_Offer {
   oid!: number;
   status!: string;
}

export class PVi_GetPropertyById_Lead {
   lid!: number;
   name!: string;
   status!: string;
   created_at!: string;
   assignees!: string[];
   properties_count!: number;
   threads?: PVi_GetPropertyById_Lead_Thread[];
   properties!: PVi_GetPropertyById_Lead_Property[];
   offers!: PVi_GetPropertyById_Lead_Offer[];
}
class PVi_GetPropertyById_Property_Tax {
   apn!: string;
   fips!: string;
   assdlandvalue!: number;
   assdimprvalue!: number;
   assdttlvalue!: number;
}

class PVi_GetPropertyById_Property_File {
   fuid!: number;
   file_name!: string;
}

export class PVi_GetPropertyById_Property {
   address_one_line!: string;
   all_in!: number;
   am_fees_monthly_override!: number;
   am_fees_monthly!: number;
   am_fees_percentage!: number;
   arv_uplift!: number;
   asking_price!: number;
   attom_arv!: string;
   attom_avm!: string;
   cap_rate!: number;
   stories!: number;
   characteristics_construction!: string;
   characteristics_drain_type!: string;
   characteristics_foundation!: string;
   characteristics_roof_age!: string;
   characteristics_roof_type!: string;
   characteristics_sewage_type!: string;
   characteristics_siding!: string;
   characteristics_stories!: string;
   characteristics_water_supply!: string;
   city!: string;
   core_closing_cost!: number;
   closing_cost!: number;
   closing_cost_override!: number;
   closing_cost_final!: number;
   estimated_gross_hud!: number;
   additional_ownership_reserve!: number;
   county_name!: string;
   data_verification!: Record<string, string | undefined>;
   estimated_acquisition_cost!: number;
   estimated_acquisition_cost_final!: number;
   estimated_acquisition_cost_override!: number;
   feature_has_attic!: boolean;
   feature_has_basement!: boolean;
   feature_has_common_laundry!: boolean;
   feature_has_common_space!: boolean;
   feature_has_deck!: boolean;
   feature_has_driveway!: boolean;
   feature_has_elevator!: boolean;
   feature_has_exterior_stairs!: boolean;
   feature_has_garage!: boolean;
   feature_has_gate!: boolean;
   feature_has_patio!: boolean;
   feature_has_pool!: boolean;
   feature_has_porch!: boolean;
   feature_has_sidewalk!: boolean;
   feature_has_yard!: boolean;
   fees_percentage!: number;
   gross_rent_final!: number;
   housing_authority_id!: number;
   housing_authority_name!: string;
   housing_authority_payment_standard_type!: string;
   insurance_monthly_override!: number;
   insurance_monthly!: number;
   lat!: number;
   lng!: number;
   market_name!: string;
   max_price_arv_uplift!: number;
   max_price_cap_rate!: number;
   neighborhood_name!: string;
   noi_annually!: number;
   noi_monthly!: number;
   payment_standard_type!: number;
   pgcalc_max_prices!: boolean;
   pid!: string;
   pm_fees_monthly_override!: number;
   pm_fees_monthly!: number;
   pm_fees_percentage!: number;
   pm_onboarding_total!: number;
   pm_onboarding_total_final!: number;
   pm_onboarding_total_override!: number;
   pm_reserve_total!: number;
   pm_reserve_total_final!: number;
   pm_reserve_total_override!: number;
   price!: number;
   property_manager_id!: number;
   property_manager_name!: string;
   property_type!: string;
   reasons!: string[];
   estimated_renovation_cost!: number;
   repair_and_maintainance_monthly_override!: number;
   repair_and_maintainance_monthly!: number;
   sid!: number;
   simply_acquisition_fee_final!: number;
   simply_acquisition_fee_override!: number | null;
   simply_acquisition_fee!: number;
   source_source!: string;
   source!: PVi_GetPropertyById_Source;
   sqft!: number;
   state!: string;
   status!: string;
   street_view_url_400!: string;
   target_purchase_price_final!: number;
   target_purchase_price_override!: number;
   target_purchase_price!: number;
   tax_monthly_override!: number;
   tax_monthly!: number;
   title_insurance_final!: number;
   title_insurance_override!: number | null;
   title_insurance!: number;
   total_owner_reserves!: number;
   total_owner_reserves_final!: number;
   total_owner_reserves_override!: number;
   transfer_tax_final!: number;
   transfer_tax_override!: number | null;
   transfer_tax!: number;
   tract_tier!: string;
   underwriting_models!: TPgSoS_SoSProperties_Schema["underwriting_models"];
   underwriting_models_calculated!: TPgSoS_SoSProperties_Schema["underwriting_models_calculated"];
   underwriting_models_max_prices!: TPgSoS_SoSProperties_Schema["underwriting_models_max_prices"];
   utilities_allowances!: PVi_GetPropertyById_Property_Utilities_Allowances;
   utilities_simply_paying_monthly_override!: number;
   utilities_simply_paying_monthly!: number;
   vacancy_monthly_override!: number;
   vacancy_monthly!: number;
   vacancy_percentage!: number;
   year_built!: number;
   zip!: string;
   locked!: boolean;
   rentometer_v1_summary_data!: PgSoS_SoSProperties_Rentometer_v1_summary_Data;
   underwriting_hcv_applicable!: boolean;
   underwriting_approved_by!: string;
   underwriting_approved_user!: PVi_GetPropertyById_Property_Approved_User;
   underwriting_approved_date!: string;
   access_details_owner!: number;
   post_purchase_cost!: number;
   tax_data?: PVi_GetPropertyById_Property_Tax;
   files?: PVi_GetPropertyById_Property_File[];
}
export class PVi_GetPropertyById_Unit_Leasing {
   lid!: number;
   type!: string;
}
export class PVi_GetPropertyById_Unit {
   uid!: number;
   bed!: number;
   bath!: number;
   gross_rent_final!: number;
   utilities_tenant_paying_final!: number;
   hcv_rent_final!: number;
   hcv_rent!: number;
   hcv_source!: string;
   access_detail_type!: string;
   access_detail_code!: string;
   utilities_config!: TPgSoS_SoSUnits_Schema_UtilitiesConfig;
   utilities_tenant_responsibility!: Record<string, boolean | undefined> | null;
   utilities_tenant_paying!: number;
   utilities_simply_paying!: number;
   leasing!: PVi_GetPropertyById_Unit_Leasing;
   refrigerator_details_exists?: boolean;
   refrigerator_details_serial?: string;
   refrigerator_details_model?: string;
   refrigerator_details_condition?: string;
   refrigerator_details_photos?: PVi_GetPropertyById_Unit_Details_Photos[];
   stove_details_exists?: boolean;
   stove_details_serial?: string;
   stove_details_model?: string;
   stove_details_condition?: string;
   stove_details_photos?: PVi_GetPropertyById_Unit_Details_Photos[];
   microwave_details_exists?: boolean;
   microwave_details_serial?: string;
   microwave_details_model?: string;
   microwave_details_condition?: string;
   microwave_details_photos?: PVi_GetPropertyById_Unit_Details_Photos[];
   hvac_details_exists?: boolean;
   hvac_details_serial?: string;
   hvac_details_model?: string;
   hvac_details_condition?: string;
   hvac_details_photos?: PVi_GetPropertyById_Unit_Details_Photos[];
}

export type PVi_GetPropertyById_Unit_Details_Photos_Fields = Pick<
   PVi_GetPropertyById_Unit,
   "refrigerator_details_photos" | "stove_details_photos" | "microwave_details_photos" | "hvac_details_photos"
>;

class PVi_GetPropertyById_Unit_Details_Photos {
   iuid!: string;
   cf_webp_original?: string;
   cf_webp_thumbnail_150?: string;
   cf_webp_thumbnail_400?: string;
}
export class PVi_GetPropertyById_Response {
   property!: PVi_GetPropertyById_Property;
   units!: PVi_GetPropertyById_Unit[];
   leads!: PVi_GetPropertyById_Lead[];
   airtable_existing?: string;
}

import { useSearchQueryParams } from "../../react/useSearchQueryParams";

export const useOffersQueryParams = () => {
   const { useSingleQueryParamsState, useMultiQueryParamsState } = useSearchQueryParams();
   const [assignee, setAssigneeIds] = useMultiQueryParamsState("assignee");
   const [cids, setContactIds] = useMultiQueryParamsState("cids");
   const [leadName, setLeadName] = useSingleQueryParamsState("leadName");
   const filters = { assignee, cids, leadName };
   const filterCount = Object.values(filters).filter((f) => (Array.isArray(f) ? f.length : f)).length;
   return { filters, filterCount, ...filters, setAssigneeIds, setContactIds, setLeadName };
};

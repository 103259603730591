import classNames from "classnames/bind";
import styles from "./PViLead.module.scss";
import { CF_CDN_URL } from "../../../../../utils/CF_CDN_URL";
import { useQPVi_Property } from "../../../../../hooks/property-viability/queries/useQPVi_Property";
import { formatDate } from "../../../../OffersPage/OffersLeadCard/OffersLeadCard";
import { useSoSOptions_Offers_Leads_Statuses } from "../../../../../hooks/queries/options/useQSoS_Options";
import { useQ_Users } from "../../../../../hooks/queries/user/useQ_Users";
import { SHrPopup, SHrSosButton } from "@simplyhomes/react";
import { useState } from "react";
import { OffersCreateNewLeadModal } from "../../../../OffersPage/OffersCreateNewLeadModal/OffersCreateNewLeadModal";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ESoS_PropertyStatus } from "@simplyhomes/utils";

const cx = classNames.bind(styles);

export const PViLead = () => {
   const nav = useNavigate();

   const qPVi_Property = useQPVi_Property();

   const qUsers = useQ_Users();

   const { leads = [], property } = qPVi_Property.data || {};

   const [isOpenCreateLeadModal, setIsOpenCreateLeadModal] = useState(false);

   const qLeadStatusesOptions = useSoSOptions_Offers_Leads_Statuses().data?.options || [];

   const statusSelected = leads.map(
      (lead) => qLeadStatusesOptions?.find((status) => status.value === lead.status)?.label
   );

   const userOptions = qUsers.data?.users.map((u) => ({ label: u.name, value: u.uid })) || [];

   const handleNavigateToLeadInOffer = (lid: number) => {
      nav({
         pathname: "/apps/offers",
         search: createSearchParams({ offers_lid: lid.toString() }).toString(),
      });
   };

   return (
      <div className={cx("container")}>
         <div className={cx("list")}>
            {!leads.length ? (
               <div className={cx("wrapper")}>
                  <div className={cx("btnAdd")}>
                     <SHrSosButton
                        type="tonal"
                        buttonProps={{
                           onClick: () => setIsOpenCreateLeadModal(true),
                           disabled: property?.status !== ESoS_PropertyStatus.VIABLE,
                        }}
                     >
                        <div className={cx("img-icon")}>
                           <img src={CF_CDN_URL("/assets/edit_black.svg")} alt="" />
                        </div>
                        Create a Lead
                     </SHrSosButton>
                  </div>
                  <SHrPopup show={isOpenCreateLeadModal} handleShow={(s) => setIsOpenCreateLeadModal(s)}>
                     <OffersCreateNewLeadModal
                        onClose={() => setIsOpenCreateLeadModal(!isOpenCreateLeadModal)}
                        pid={property?.pid || ""}
                        sid={property?.sid || 0}
                     />
                  </SHrPopup>
               </div>
            ) : (
               leads.map((lead) => (
                  <div className={cx("card")}>
                     <div className={cx("name")}>
                        <span>{lead.name}</span>
                        <div className={cx("img-icon")} onClick={() => handleNavigateToLeadInOffer(lead.lid)}>
                           <img src={CF_CDN_URL("/assets/open_in_new_black.svg")} alt="" />
                        </div>
                     </div>
                     <div className={cx("infor")}>
                        <div className={cx("img-icon")}>
                           <img src={CF_CDN_URL("/assets/property_viability/home_round_black.svg")} alt="" />
                        </div>
                        <span>Properties</span>
                        <div className={cx("value")}>{lead.properties_count}</div>
                     </div>
                     <div className={cx("infor")}>
                        <div className={cx("img-icon")}>
                           <img src={CF_CDN_URL("/assets/property_viability/incomplete_circle_black.svg")} alt="" />
                        </div>
                        <span>Status</span>
                        <div
                           className={cx("valueStatus")}
                           style={{
                              backgroundColor: qLeadStatusesOptions?.find((status) => status.value === lead.status)
                                 ?.color,
                           }}
                        >
                           {statusSelected}
                        </div>
                     </div>
                     <div className={cx("infor")}>
                        <div className={cx("img-icon")}>
                           <img src={CF_CDN_URL("/assets/property_viability/event_black.svg")} alt="" />
                        </div>
                        <span>Created at</span>
                        <div className={cx("value")}>{formatDate(lead.created_at)}</div>
                     </div>
                     <div className={cx("infor")}>
                        <div className={cx("img-icon")}>
                           <img src={CF_CDN_URL("/assets/property_viability/person_black.svg")} alt="" />
                        </div>
                        <span>Assignee</span>
                        <div className={cx("value")}>
                           {lead.assignees
                              .map((assignee) => {
                                 const user = userOptions.find((o) => o.value === assignee);
                                 return user ? user.label : "No assignee";
                              })
                              .join(", ")}
                        </div>
                     </div>
                  </div>
               ))
            )}
         </div>
      </div>
   );
};

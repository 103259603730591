import { useMDatabase_DownloadFile } from "../../../hooks/database/mutations/useMDatabase_DownloadFile";

export const TestDownloadFile = () => {
   const mDownloadFile = useMDatabase_DownloadFile();
   return (
      <div>
         <button onClick={() => mDownloadFile.mutate({ fuid: 36, fileNameAndExtension: "VALID_CLEVELAND_MSL.csv" })}>
            Download
         </button>
      </div>
   );
};

import { createContext, useContext, useEffect, useReducer } from "react";
import { Database_Table } from "../hooks/database/mutations/utils";
import { PVi_GetFilterSortTemplates_Template_Data } from "../hooks/property-viability/queries/useQPVi_FilterSortTemplates";

class PViState {
   constructor(init?: Partial<PViState>) {
      if (init) Object.assign(this, init);
   }
   // showDetailsModal: boolean = true;
   // pid: string = "";
   sid: number = 0;
   isViewCommented: boolean = false;
   locked: boolean = false;
   excludedPropertyIds: string[] = [];
   isRunStepJoyRideInPropertyBrowser: boolean = false;
   isRunStepJoyRideInPropertyDetails: boolean = false;
   isShowChangesHistoryModal: boolean = false;
   fieldInfoCurrentvalue: string | number | undefined | null | boolean | (string | number)[] = null;
   history: { column?: string; record_id: string; table: Database_Table } | null = null;
   fstid: number = 0;
   filterSortTemplateName: string = "";
   filterSortTemplateData: PVi_GetFilterSortTemplates_Template_Data = {
      sort: "",
      filter: {
         states: [],
         markets: [],
         zips: [],
         propertyTypes: [],
         sources: [],
         hasLead: undefined,
         offerStatuses: [],
         status: "",
         view: "",
         minSourcePrice: undefined,
         maxSourcePrice: undefined,
      },
   };
}

type TPVUpAction = { type: "PVI_RESET_FILTER_SORT" } | { overwrite: TPViOverwrite };
type TPViOverwrite = Partial<PViState>;
export const PVContext = createContext<{ pviState: PViState; pviDispatch: React.Dispatch<TPVUpAction> }>({
   pviState: new PViState(),
   pviDispatch: () => {},
});

const pviReducer = (state: PViState, action: TPVUpAction): PViState => {
   if ("overwrite" in action) {
      return { ...state, ...action.overwrite };
   }
   if (action.type === "PVI_RESET_FILTER_SORT") {
      return {
         ...state,
         fstid: 0,
         filterSortTemplateName: "",
         filterSortTemplateData: {
            sort: "",
            filter: {
               states: [],
               markets: [],
               zips: [],
               propertyTypes: [],
               sources: [],
               offerStatuses: [],
               hasLead: undefined,
               status: "",
               view: "",
               maxSourcePrice: undefined,
               minSourcePrice: undefined,
            },
         },
      };
   }
   switch (action.type) {
      default:
         break;
   }
   return state;
};

export const PViProvider = ({ children, state }: { children: React.ReactNode; state?: Partial<PViState> }) => {
   const [pviState, pviDispatch] = useReducer(pviReducer, new PViState(state));
   useEffect(() => {
      if (state) pviDispatch({ overwrite: state });
   }, [state]);
   return <PVContext.Provider value={{ pviState, pviDispatch }}>{children}</PVContext.Provider>;
};

export const usePViContext = () => {
   const context = useContext(PVContext);
   const pviOverwrite = (overwrite: TPViOverwrite) => context.pviDispatch({ overwrite });
   return { ...context, pviOverwrite };
};

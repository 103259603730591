import classNames from "classnames/bind";
import styles from "./SoSUploaderContainer.module.scss";
import { SHrPopup, SHrSpinner } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { SoSImageViewer } from "../../SoSImageViewer/SoSImageViewer";
import { SoSUploaderWrapper } from "../../SoSUploaderWrapper/SoSUploaderWrapper";
import { SoSConfirmWrapper } from "../SoSConfirmWrapper/SoSConfirmWrapper";
import { SoSDataPoint } from "../../SoSDataPoint/SoSDataPoint";
import { database_helpers_createPayloadHelper as dbcb } from "../../../hooks/database/mutations/utils";
import { useQPVi_Property } from "../../../hooks/property-viability/queries/useQPVi_Property";
import { useState } from "react";
import { SoSViewerUploadFilesModal } from "../SoSViewerUploadFilesModal/SoSViewerUploadFilesModal";
import { useMDatabase_DownloadFile } from "../../../hooks/database/mutations/useMDatabase_DownloadFile";

const cx = classNames.bind(styles);

export const SoSUploaderContainer = ({
   type,
   values,
   onUpload,
   onRemove,
   onDownload,
   onRename,
   children,
}: SoSUploaderContainerProps) => {
   const qGetProperty = useQPVi_Property();
   const [isOpenViewerUploadFilesModal, setIsOpenViewerUploadFilesModal] = useState(false);
   const [uriToView, setUriToView] = useState<string | null>(null);
   const [loadingFileId, setLoadingFileId] = useState<number | null>(null);
   const { mutate: getFile, isPending } = useMDatabase_DownloadFile({
      downloading: false,
      onSuccess: (data) => {
         setUriToView(data);
         setIsOpenViewerUploadFilesModal(true);
         setLoadingFileId(null);
      },
      onError: () => {
         setLoadingFileId(null);
      },
   });

   return type === "files" ? (
      <div className={cx("container")}>
         <SoSUploaderWrapper type="files" onUpload={onUpload}>
            {children}
         </SoSUploaderWrapper>

         <div className={cx("linkC")}>
            {(values as SoSUploaderContainerFileType[])?.map((file, index) => (
               <div key={index} className={cx("linkUploadC")}>
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/property_validator/insert_drive_file_black.svg")} alt="" />
                  </div>

                  <div className={cx("renameInput")}>
                     <SoSDataPoint
                        type="only-value"
                        value={file.file_name}
                        database={dbcb("sos_file_uploads", [{ column: "fuid", id: file.fuid }], () => {
                           if (file.loading) return;
                           qGetProperty.refetch();
                        })("name")}
                     />
                  </div>

                  <div className={cx("linkIconsC")}>
                     {(file.loading || (loadingFileId === file.fuid && isPending)) && <SHrSpinner />}
                     <div
                        className={cx("img-icon", {
                           disabled: file.loading,
                        })}
                        onClick={() => {
                           setLoadingFileId(file.fuid);
                           getFile({ fuid: file.fuid, fileNameAndExtension: file.file_name });
                           console.log(file.file_name);
                        }}
                     >
                        <img
                           className={cx({
                              loading: file.loading,
                           })}
                           src={CF_CDN_URL("/assets/remove_red_eye_black.svg")}
                           alt=""
                        />
                     </div>
                     <SHrPopup
                        show={isOpenViewerUploadFilesModal}
                        handleShow={(s) => setIsOpenViewerUploadFilesModal(s)}
                     >
                        <SoSViewerUploadFilesModal
                           onClose={() => setIsOpenViewerUploadFilesModal(!isOpenViewerUploadFilesModal)}
                           uri={uriToView || ""}
                           fileName={file.file_name}
                        />
                     </SHrPopup>
                     {onDownload && (
                        <div
                           className={cx("img-icon", {
                              disabled: file.loading,
                           })}
                           onClick={() => {
                              if (file.loading) return;
                              onDownload(file.fuid, file.file_name);
                           }}
                        >
                           <img
                              className={cx({
                                 loading: file.loading,
                              })}
                              src={CF_CDN_URL("/assets/property_validator/download_black.svg")}
                              alt=""
                           />
                        </div>
                     )}
                     {onRemove && (
                        <SoSConfirmWrapper
                           type="default"
                           title="File will be disassociated, are you sure?"
                           content="This action allows to delete your file."
                           onConfirm={() => {
                              if (file.loading) return;
                              onRemove(file.fuid);
                           }}
                           onCancel={() => {}}
                        >
                           <div
                              className={cx("img-icon", "clear", {
                                 disabled: file.loading,
                              })}
                           >
                              <img
                                 className={cx({
                                    loading: file.loading,
                                 })}
                                 src={CF_CDN_URL("/assets/clear_black.svg")}
                                 alt=""
                              />
                           </div>
                        </SoSConfirmWrapper>
                     )}
                  </div>
               </div>
            ))}
         </div>
      </div>
   ) : (
      <div className={cx("container")}>
         <div className={cx("linkC", "linkC-image")}>
            <SoSImageViewer
               images={(values as SoSUploaderContainerImageType[])?.filter((i) => !i.loading).map((i) => i.url)}
            />
            {(values as SoSUploaderContainerImageType[])
               ?.filter((i) => i.loading)
               .map((image, i) => (
                  <div className={cx("uploading-image")}>
                     <img key={i} src={image.url} alt="" className={cx("images")} />
                     <div className={cx("spinner")}>
                        <SHrSpinner />
                     </div>
                  </div>
               ))}

            <SoSUploaderWrapper type="images" onUpload={onUpload}>
               {children}
            </SoSUploaderWrapper>
         </div>
      </div>
   );
};
// const SoSViewerUploadFilesModal = () => {
//    return <div>housing_authority_id</div>;
// };
type SoSUploaderContainerBaseProps = {
   values?: SoSUploaderContainerFileType[] | SoSUploaderContainerImageType[];
   children?: React.ReactNode;
   onUpload: (files: FileList) => void;
};

type SoSUploaderContainerFileProps = SoSUploaderContainerBaseProps & {
   type: "files";
   values?: SoSUploaderContainerFileType[];
   onRemove?: (fuid: number) => void;
   onDownload?: (fuid: number, fileNameAndExtension: string) => void;
   onRename?: (fuid: number, fileNameAndExtension: string) => void;
};

type SoSUploaderContainerImageProps = SoSUploaderContainerBaseProps & {
   type: "images";
   values?: SoSUploaderContainerImageType[];
   onRemove?: (iuid: string) => void;
   onDownload?: (iuid: string, fileNameAndExtension: string) => void;
   onRename?: (fuid: number, fileNameAndExtension: string) => void;
};

type SoSUploaderContainerProps = SoSUploaderContainerFileProps | SoSUploaderContainerImageProps;

export type SoSUploaderContainerFileType = {
   fuid: number;
   file_name: string;
   loading?: boolean;
};

export type SoSUploaderContainerImageType = {
   iuid: string;
   url: string;
   loading?: boolean;
};

import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useToastContext } from "../../../../contexts/ToastContext";
import { useQConvo_Threads } from "../../queries/threads/useQConvo_Threads";
import { useQConvo_ThreadComments } from "../../queries/threads/useQConvo_ThreadComments";
import { useSearchQueryParams_Convo } from "../../../react/useSearchQueryParams";

type TuseMConvo_CreateCommentProps = {
   type: "property" | "lead" | "transaction";
};

export const useMConvo_CreateComment = ({ type }: TuseMConvo_CreateCommentProps) => {
   const { sosAxios } = useSosAxios();
   const { toastDispatch } = useToastContext();
   const [tid] = useSearchQueryParams_Convo().tid;
   const qConvoGetThreads = useQConvo_Threads({ type });
   const qConvoGetComments = useQConvo_ThreadComments();

   return useMutation({
      onSuccess: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Create new comment is successful!",
            },
         });
         qConvoGetThreads.refetch();
         qConvoGetComments.refetch();
      },
      onError: (error) =>
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Create comment lead is failed!",
            },
         }),
      mutationFn: ({ content }: CreateCommentInThread) =>
         sosAxios
            .post(`/conversations/threads/${tid}/comments`, { content })
            .then<TConversations_CreateCommentInThread_Response>(({ data }) => data),
   });
};

export type CreateCommentInThread = { content: string };

// type TUseMConvo_CreateCommentInThreadProps = {
//    onSuccess: (data: TConversations_CreateCommentInThread_Response) => void;
//    onError: (message: string) => void;
// };

export type TConversations_CreateCommentInThread_Response = {
   cid: number;
};

import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";

const mapingMineTypes = {
   jpg: "image/jpeg",
   jpeg: "image/jpeg",
   png: "image/png",
   pdf: "application/pdf",
   doc: "application/msword",
   docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
   xls: "application/vnd.ms-excel",
   xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
   ppt: "application/vnd.ms-powerpoint",
   pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
   webp: "image/webp",
   svg: "image/svg+xml",
   gif: "image/gif",
   tiff: "image/tiff",
   txt: "text/plain",
   csv: "text/csv",
   zip: "application/zip",
   json: "application/json",
};

export const useMDatabase_DownloadFile = (
   { onSuccess, onError, downloading = true }: UseMDatabase_DownloadFile_Props = {
      onSuccess: () => {},
      onError: () => {},
   }
) => {
   const { sosAxios } = useSosAxios();
   const mutation = useMutation({
      onSuccess: (data) => {
         onSuccess && onSuccess(data);
      },
      onError: (message: string) => {
         onError && onError(message);
      },
      mutationFn: ({ fuid, fileNameAndExtension }: useMDatabase_DownloadFile_Params) => {
         if (!fuid || !fileNameAndExtension) {
            throw new Error("Invalid parameters");
         }

         return sosAxios.get(`/database/files/${fuid}`, { responseType: "blob" }).then(({ data }) => {
            const getType = fileNameAndExtension.split(".")[1] as keyof typeof mapingMineTypes;
            const blob = new Blob([data], { type: mapingMineTypes[getType] || "application/octet-stream" });
            const url = window.URL.createObjectURL(blob);
            if (!downloading) return url;
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileNameAndExtension);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Clean up
         });
      },
   });
   return { ...mutation };
};

type UseMDatabase_DownloadFile_Props = {
   onSuccess?: (data: any) => void;
   onError?: (message: string) => void;
   downloading?: boolean;
};

export type useMDatabase_DownloadFile_Params = {
   fuid: number;
   fileNameAndExtension: string;
};
// export const useMDatabase_DownloadFile = async (fileUrl: string, fileName: string) => {
//    const { sosAxios } = useSosAxios();

//    try {
//       const response = await sosAxios.get(fileUrl, {
//          responseType: 'blob', // Important for downloading files
//       });

//       // Create a URL for the file
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//       const link = document.createElement('a');
//       link.href = url;
//       link.setAttribute('download', fileName); // Set the file name
//       document.body.appendChild(link);
//       link.click();

//       // Clean up and remove the link
//       link.parentNode?.removeChild(link);
//    } catch (error) {
//       console.error('Error downloading the file', error);
//    }
// };

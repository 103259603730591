import classNames from "classnames/bind";
import styles from "./SoSCopyToClipboard.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { useState } from "react";

const cx = classNames.bind(styles);

export const SoSCopyToClipboard = ({ value, placement = "right", type, title }: TSoSCopyToClipboardProps) => {
   const [copySuccess, setCopySuccess] = useState<boolean>(false);

   const handleCopyClick = () => {
      if (value) {
         navigator.clipboard
            .writeText(value)
            .then(() => {
               setCopySuccess(true);
               setTimeout(() => setCopySuccess(false), 2000);
            })
            .catch((err) => {
               console.error("Failed to copy: ", err);
            });
      }
   };
   return (
      <div className={cx("container", "_jr_pviAddressCopy")}>
         {type === "icon" ? (
            <div className={cx("img-icon", "cursor-pointer")} onClick={handleCopyClick}>
               <img src={CF_CDN_URL("/assets/offer/content_copy_black.svg")} alt="" />
            </div>
         ) : type === "text" ? (
            <span className={cx("title")} onClick={handleCopyClick}>
               {title}
            </span>
         ) : null}
         {copySuccess && (
            <span
               className={cx("copy-alert", {
                  "placement-right": placement === "right",
                  "placement-top-left": placement === "top-left",
                  "placement-left": placement === "left",
               })}
            >
               Copied to clipboard!
            </span>
         )}
      </div>
   );
};

type TSoSCopyToClipboardProps = {
   type: "icon" | "text";
   title?: string;
   value: string;
   placement?: "top-left" | "right" | "left";
};

import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useSearchQueryParams_Offers, useSearchQueryParams_PVi } from "../../../react/useSearchQueryParams";
import { useQConvo_Threads } from "../../queries/threads/useQConvo_Threads";
import { useQPVi_Property } from "../../../property-viability/queries/useQPVi_Property";
import { useQOffers_Lead } from "../../../offers/queries/leads/useQOffers_GetLead";

export const useMConvo_CreateThread = ({
   onSuccess,
   onError,
   type,
   lid: lidParam,
   pid: pidParam,
}: TUseMConvo_CreateThreadProps) => {
   const { sosAxios } = useSosAxios();

   const [pid] = useSearchQueryParams_PVi().pid;

   const [lid] = useSearchQueryParams_Offers().lid;

   const qGetProperty = useQPVi_Property();

   const qGetLead = useQOffers_Lead();

   const qThreads = useQConvo_Threads({
      type,
      lid: lidParam || +lid,
      pid: pidParam || pid,
   });

   return useMutation({
      onSuccess: (d) => {
         qThreads.refetch();
         if (lidParam) {
            qGetProperty.refetch();
         }
         if (pidParam) {
            qGetLead.refetch();
         }
         if (onSuccess) onSuccess(d);
      },
      onError,
      mutationFn: (body: TCreateThreadParams) =>
         sosAxios
            .post(`/conversations/threads/`, {
               ...body,
               ...(type === "property" && lidParam
                  ? { lid: lidParam }
                  : type === "lead" && pidParam
                  ? { pid: pidParam }
                  : {
                       pid: type === "property" ? pid : null,
                       lid: type === "lead" ? lid : null,
                    }),
            })
            .then<TConversations_CreateThread_Response>(({ data }) => data),
   });
};

export type TCreateThreadParams = { title: string; content: string; tags: string[] };

type TUseMConvo_CreateThreadProps = {
   onSuccess: (data: TConversations_CreateThread_Response) => void;
   onError: (message: string) => void;
   type: "property" | "lead" | "transaction";
   lid?: number;
   pid?: string;
};

export type TConversations_CreateThread_Response = { tid: number };

import classNames from "classnames/bind";

import styles from "./PViPropertyCard.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { SHu_js_currencyFormat } from "@simplyhomes/utils";
import { PVi_GetProperties_Property } from "../../../hooks/property-viability/queries/useQPVi_Properties";
import {
   useSoSOptions_Offers_Leads_Statuses,
   useSoSOptions_Offers_OfferStatuses,
} from "../../../hooks/queries/options/useQSoS_Options";

const cx = classNames.bind(styles);

export const PViPropertyCard = ({ property, onClick }: PropertyCardProps) => {
   const {
      units = [],
      street_view_url_150,
      state,
      price,
      address_one_line,
      sqft,
      max_price_arv_uplift,
      lead,
   } = property;
   const totalBeds = units?.map((u) => u.beds).reduce((acc, value) => acc + value, 0);
   const totalBaths = units?.map((u) => u.baths).reduce((acc, value) => acc + value, 0);

   const qOpLeadStatuses = useSoSOptions_Offers_Leads_Statuses();
   const qOpOfferStatus = useSoSOptions_Offers_OfferStatuses();
   const leadStatuses = qOpLeadStatuses.data?.options || [];
   const offerStatus = qOpOfferStatus.data?.options || [];

   const color = leadStatuses.find((o) => o.value === lead?.status)?.color;
   const colorOffer = offerStatus.find((o) => o.value === lead?.offer?.status)?.color;

   return (
      <div className={cx("cardC")} onClick={onClick}>
         <div className={cx("property-img")}>
            <div className={cx("imgC")}>{street_view_url_150 && <img src={street_view_url_150} alt="" />}</div>
            <span className={cx("state", "statusPill")}>{state}</span>

            <div className={cx("statusC")}>
               {lead?.status && (
                  <span className={cx("statusPill")} style={{ background: `${color}` }}>
                     {leadStatuses.find((o) => o.value === lead?.status)?.label}
                  </span>
               )}
               {lead?.offer?.status && (
                  <span className={cx("statusPill")} style={{ background: `${colorOffer}` }}>
                     {offerStatus.find((o) => o.value === lead?.offer?.status)?.label || lead.offer.status}
                  </span>
               )}
            </div>

         </div>

         <div className={cx("detailC")}>
            <div className={cx("numbersC")}>
               <div className={cx("price")}>
                  <span>Asking Price</span>
                  <span className={cx("number-price")}>{SHu_js_currencyFormat(price).split(".")[0]}</span>
               </div>
               <div className={cx("price")}>
                  <span>Current Max Price</span>
                  <span className={cx("number-price")}>
                     {max_price_arv_uplift ? SHu_js_currencyFormat(max_price_arv_uplift).split(".")[0] : "N/A"}
                  </span>
               </div>
            </div>

            <div className={cx("addressC")}>
               <div className={cx("icon")}>
                  <img src={CF_CDN_URL("/assets/property_validator/location_on_black.svg")} alt="" />
               </div>
               <span>{address_one_line}</span>
            </div>

            <div className={cx("inforC")}>
               <div className={cx("unit")}>
                  <div className={cx("icon")}>
                     <img src={CF_CDN_URL("/assets/property_validator/square_foot_black.svg")} alt="" />
                  </div>
                  <span>
                     {sqft} ft <sup>2</sup>
                  </span>
               </div>
               <div className={cx("unit")}>
                  <div className={cx("icon")}>
                     <img src={CF_CDN_URL("/assets/property_validator/house_siding_black.svg")} alt="" />
                  </div>
                  <span>{units?.length} unit</span>
               </div>
               <div className={cx("unit")}>
                  <div className={cx("icon")}>
                     <img src={CF_CDN_URL("/assets/property_validator/bedroom_parent_black.svg")} alt="" />
                  </div>
                  <span>
                     {totalBeds}
                     {`${totalBeds > 1 ? " beds" : " bed"}`}
                  </span>
               </div>
               <div className={cx("unit")}>
                  <div className={cx("icon")}>
                     <img src={CF_CDN_URL("/assets/property_validator/bathroom.svg")} alt="" />
                  </div>
                  <span>
                     {totalBaths}
                     {`${totalBaths > 1 ? " baths" : " bath"}`}
                  </span>
               </div>
            </div>
         </div>
      </div>
   );
};

type PropertyCardProps = {
   property: PVi_GetProperties_Property;
   onClick?: () => void;
};

import { useMutation } from "@tanstack/react-query";
import { useToastContext } from "../../../../contexts/ToastContext";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useSearchQueryParams_Convo } from "../../../react/useSearchQueryParams";
import { useQConvo_Threads } from "../../queries/threads/useQConvo_Threads";

type TuseMConvo_UpdateThreadProps = {
   type: "property" | "lead" | "transaction";
};

export const useMConvo_UpdateThread = ({ type }: TuseMConvo_UpdateThreadProps) => {
   const { sosAxios } = useSosAxios();

   const { toastDispatch } = useToastContext();

   const [tid] = useSearchQueryParams_Convo().tid;

   const qConvoGetThreads = useQConvo_Threads({ type });

   return useMutation({
      onSuccess: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Update thread is successful!",
            },
         });
         qConvoGetThreads.refetch();
      },
      onError: (error) =>
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Update thread is failed!",
            },
         }),
      mutationFn: ({ title, content, tags }: Conversations_UpdateThread_Body) =>
         sosAxios
            .patch(`/conversations/threads/${tid}`, { content, tags, title })
            .then<Conversations_UpdateThread_Response>(({ data }) => data),
   });
};

export class Conversations_UpdateThread_Body {
   title?: string;
   content?: string;
   tags?: string[];
}

export class Conversations_UpdateThread_Response {
   message!: string;
}

import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useToastContext } from "../../../../contexts/ToastContext";
import { useQConvo_Threads } from "../../queries/threads/useQConvo_Threads";
import { useQPVi_Property } from "../../../property-viability/queries/useQPVi_Property";
import { useQOffers_Lead } from "../../../offers/queries/leads/useQOffers_GetLead";

type TuseMConvo_DeleteThreadProps = {
   type: "property" | "lead" | "transaction";
   lid?: number;
   pid?: string;
};
export const useMConvo_DeleteThread = ({ type, lid, pid }: TuseMConvo_DeleteThreadProps) => {
   const { sosAxios } = useSosAxios();

   const { toastDispatch } = useToastContext();

   const qThreads = useQConvo_Threads({
      type,
      lid,
      pid,
   });

   const qGetProperty = useQPVi_Property();

   const qGetLead = useQOffers_Lead();

   return useMutation({
      onSuccess: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Delete this thread is successful!",
            },
         });
         qThreads.refetch();
         if (lid) {
            qGetProperty.refetch();
         }
         if (pid) {
            qGetLead.refetch();
         }
      },
      onError: (error) =>
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Delete this thread is failed!",
            },
         }),
      mutationKey: ["useMConvo_DeleteThread", pid, lid],
      mutationFn: ({ tid }: Conversations_DeleteThread_Param) =>
         sosAxios.delete(`/conversations/threads/${tid}`).then<Conversations_DeleteThread_Response>(({ data }) => data),
   });
};

export class Conversations_DeleteThread_Param {
   tid!: number;
}

export class Conversations_DeleteThread_Response {
   message!: string;
}

import classNames from "classnames/bind";
import styles from "./OffersLeadDetailSetting.module.scss";
import { OffersLeadOverview } from "../OffersLeadOverview/OffersLeadOverview";
import { OffersProperties } from "../OffersProperties/OffersProperties";
import { OffersInspectionAndSource } from "../OffersInspectionAndSource/OffersInspectionAndSource";
// import { OffersTerms } from "../OffersTerms/OffersTerms";
import { OffersOffersSection } from "../OffersOffersSection/OffersOffersSection";
import { OffersRelactedContact } from "../OffersRelactedContact/OffersRelactedContact";
import { SoSConversation } from "../../../SoSConversation/SoSConversation";
import { useOffersContext } from "../../../../contexts/OffersContext";

const cx = classNames.bind(styles);

export const OffersLeadDetailSetting = () => {
   const { isViewComment, pid } = useOffersContext().offersState;

   return (
      <div className={cx("rightBodyC")}>
         <div className={cx("rightBodyLeftC")}>
            <OffersLeadOverview />
            <OffersProperties />
            {pid && (
               <>
                  <OffersInspectionAndSource />
                  <OffersRelactedContact />
               </>
            )}
            {/* <OffersTerms /> */}
            <OffersOffersSection />
         </div>
         <div className={cx("rightBodyRightC", { hidden: !isViewComment })}>
            <SoSConversation type="lead" />
         </div>
      </div>
   );
};

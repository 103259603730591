import classNames from "classnames/bind";
import styles from "./OffersPropertiesSelection.module.scss";
import { SoSCheckbox } from "../../../components/common/SoSCheckbox/SoSCheckbox";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { SoSCopyToClipboard } from "../../../components/common/SoSCopyToClipboard/SoSCopyToClipboard";
import { SHu_js_currencyFormat } from "@simplyhomes/utils";
import { SoSInputTitle } from "../../../components/common/SoSInputTitle/SoSInputTitle";
import { useMemo } from "react";
import { TSoS_Offers_GetLeadSourceProperties_Property } from "../../../hooks/offers/queries/leads/useQOffers_GetLeadSourceProperties";

const cx = classNames.bind(styles);

type OffersPropertiesSelectionProps = {
   property: TSoS_Offers_GetLeadSourceProperties_Property;
   value?: boolean;
   onSelectProperty?: (value: boolean) => void;
   type?: "select";
   price?: string;
   handleChangePrice?: (price: string) => void;
   disabled?: boolean;
};

export const OffersPropertiesSelection = ({
   type,
   price,
   property,
   value,
   handleChangePrice,
   onSelectProperty,
   disabled,
}: OffersPropertiesSelectionProps) => {
   const totalBeds = useMemo(
      () => property?.units?.map((u) => u.beds).reduce((acc, value) => acc + value, 0),
      [property?.units]
   );
   const totalBaths = useMemo(
      () => property?.units?.map((u) => u.baths).reduce((acc, value) => acc + value, 0),
      [property?.units]
   );

   if (!property) return null;

   const { street_view_url_150, address_one_line, asking_price, units, sqft, max_price_arv_uplift } = property;
   const contactList = property?.contacts;

   return (
      <div className={cx("sourcesC", { borderC: price })}>
         <div className={cx("container")}>
            <div className={cx("checkboxAndSourceC")}>
               {type === "select" && (
                  <SoSCheckbox value={value!} onChange={onSelectProperty!} inputProps={{ disabled: disabled }} />
               )}

               <div className={cx("sourceC")}>
                  <div className={cx("property-img")}>
                     <img src={street_view_url_150} alt="" />
                  </div>
                  <div className={cx("inforC")}>
                     <div className={cx("addressC")}>
                        <div className={cx("address")}>
                           <div className={cx("img-icon")}>
                              <img src={CF_CDN_URL("/assets/offer/location_on_black.svg")} alt="" />
                           </div>
                           <span>{address_one_line}</span>
                        </div>
                        <SoSCopyToClipboard value={address_one_line} placement="top-left" type="icon" />
                     </div>
                     <div className={cx("numbersC")}>
                        <div className={cx("price")}>
                           <span>Asking Price</span>
                           <span className={cx("number-price")}>{SHu_js_currencyFormat(asking_price)}</span>
                        </div>
                        <div className={cx("price")}>
                           <span>Current Max Price</span>
                           <span className={cx("number-price")}>
                              {max_price_arv_uplift ? SHu_js_currencyFormat(max_price_arv_uplift) : "N/A"}
                           </span>
                        </div>
                     </div>
                     <div className={cx("unitInforC")}>
                        <div className={cx("unit")}>
                           <div className={cx("icon")}>
                              <img src={CF_CDN_URL("/assets/property_validator/square_foot_black.svg")} alt="" />
                           </div>
                           <span>
                              {sqft} ft <sup>2</sup>
                           </span>
                        </div>
                        <div className={cx("unit")}>
                           <div className={cx("icon")}>
                              <img src={CF_CDN_URL("/assets/property_validator/house_siding_black.svg")} alt="" />
                           </div>
                           <span>
                              {units?.length} {`${units?.length > 1 ? " units" : " unit"}`}
                           </span>
                        </div>
                        <div className={cx("unit")}>
                           <div className={cx("icon")}>
                              <img src={CF_CDN_URL("/assets/property_validator/bedroom_parent_black.svg")} alt="" />
                           </div>
                           <span>
                              {totalBeds}
                              {`${totalBeds > 1 ? " beds" : " bed"}`}
                           </span>
                        </div>
                        <div className={cx("unit")}>
                           <div className={cx("icon")}>
                              <img src={CF_CDN_URL("/assets/property_validator/bathroom.svg")} alt="" />
                           </div>
                           <span>
                              {totalBaths}
                              {`${totalBaths > 1 ? " baths" : " bath"}`}
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className={cx("contactListC")}>
               {!contactList?.length ? (
                  <div className={cx("noContact")}>No property's contact found</div>
               ) : (
                  contactList?.map((contact, i) => (
                     <div key={i} className={cx("contactC")}>
                        <div className={cx("contactInfo")}>
                           <div className={cx("img-icon")}>
                              <img src={CF_CDN_URL("/assets/offer/person_black.svg")} alt="" />
                           </div>
                           <span>{contact.name || "Ethan Harrison"}</span>
                        </div>
                        <div className={cx("contactInfo")}>
                           <div className={cx("img-icon")}>
                              <img src={CF_CDN_URL("/assets/offer/email_black.svg")} alt="" />
                           </div>
                           <span>{contact.email}</span>
                        </div>
                        <div className={cx("contactInfo")}>
                           <div className={cx("img-icon")}>
                              <img src={CF_CDN_URL("/assets/offer/phone_black.svg")} alt="" />
                           </div>
                           <span>{contact.phone_primary || "(000) 000 0000"}</span>
                        </div>
                     </div>
                  ))
               )}
            </div>

            {price && (
               <div className={cx("input_search")}>
                  <SoSInputTitle
                     value={price}
                     type="text"
                     handleValue={handleChangePrice}
                     placeholder="Enter your price..."
                  />
               </div>
            )}
         </div>
      </div>
   );
};

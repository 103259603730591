import classNames from "classnames/bind";
import styles from "./PViUnderwriting.module.scss";
import { useQPVi_Property } from "../../../../../hooks/property-viability/queries/useQPVi_Property";
import { database_helpers_createPayloadHelper as dbcb } from "../../../../../hooks/database/mutations/utils";
import {
   useMDatabase_UpdateValue,
   useMDb_P_UpdateUwModels,
} from "../../../../../hooks/database/mutations/useMDatabase_UpdateValue";
import { SHrPopup, SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { useState } from "react";
import { SoSRoudedTabs } from "../../../../../components/common/SoSRoudedTabs/SoSRoudedTabs";
import { PViAddNewModelModal } from "../../PViAddNewModelModal/PViAddNewModelModal";
import { CF_CDN_URL } from "../../../../../utils/CF_CDN_URL";
import { PgSoS_SoSProperties_UnderwritingModel } from "@simplyhomes/utils/dist/types/pg/sos/properties";
import { PViHCVRentTab } from "./PViHCVRentTab/PViHCVRentTab";
import { PViCustomRent } from "./PViCustomRent/PViCustomRent";
import { PViDataPoint } from "../../../../../components/PViDataPoint/PViDataPoint";
import { SoSCheckbox } from "../../../../../components/common/SoSCheckbox/SoSCheckbox";
import { useMPVi_UnderWritingApproval } from "../../../../../hooks/property-viability/mutates/properties/useMPVi_UnderWritingApproval";

const cx = classNames.bind(styles);

const formatDate = (dateString: string): string => {
   if (!dateString) return "";

   const date = new Date(dateString);

   const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      day: "numeric",
      month: "short",
      year: "numeric",
   };

   return new Intl.DateTimeFormat("en-US", options).format(date);
};

type PViUnderwritingProps = {
   pid: string;
};

export const PViUnderwriting = ({ pid }: PViUnderwritingProps) => {
   const qPVi_Property = useQPVi_Property();

   const { property: p } = qPVi_Property.data || {};

   const db_pid = { column: "pid", id: pid || "" };

   const [activeTab, setActiveTab] = useState("HCV rent");

   const [isOpenCreateRentTypeModal, setIsOpenCreateRentTypeModal] = useState(false);

   const db_P = dbcb("sos_properties", [db_pid], qPVi_Property.refetch);

   const mDb_P_underwritingHcvApplicable = useMDatabase_UpdateValue(db_P("underwriting_hcv_applicable"));

   const mDb_P_dataVerification = useMDatabase_UpdateValue(db_P("pgcalc_max_prices"));

   const mDb_P_recalc = useMDatabase_UpdateValue(db_P("pgcalc_underwriting_models"));

   const mDb_P_uwModels = useMDatabase_UpdateValue(db_P("underwriting_models"));

   const mDb_P_applicableInModel = useMDb_P_UpdateUwModels({ pid: p?.pid });

   const { mutate: mutateUWApproval, isPending: isPendingUWApproval } = useMPVi_UnderWritingApproval();

   if (!p) return null;

   const models = p.underwriting_models?.reduce(
      (prev, model, index) => ({ ...prev, [model.name]: { ...model, index } }),
      {} as Record<string, (PgSoS_SoSProperties_UnderwritingModel & { index: number }) | undefined>
   );

   const selectedModel = models?.[activeTab];

   const handleDeleteModel = () => {
      if (!selectedModel) return;
      const newModels = p.underwriting_models?.filter((_, i) => i !== selectedModel.index);
      mDb_P_uwModels.mutate({ value: JSON.stringify(newModels) });
      setIsOpenCreateRentTypeModal(false);
      setActiveTab("HCV rent");
   };

   const handleChangeApplicable = (index: number) => {
      const model = p.underwriting_models?.[index];
      if (!model) return;
      const newModel = { ...model, applicable: !model.applicable };
      const newModels = p.underwriting_models?.map((m, i) => (i === index ? newModel : m));
      mDb_P_applicableInModel.mutate({ value: JSON.stringify(newModels) });
   };

   return qPVi_Property.isLoading ? (
      <div className={cx("loading")}>
         <SHrSpinner /> <span>Loading...</span>
      </div>
   ) : (
      <div className={cx("container")}>
         <div className={cx("applicableC", "approve")}>
            <span>Approval</span>

            <div className={cx("wrapper")}>
               {p.underwriting_approved_by && p.underwriting_approved_date && (
                  <div className={cx("inforC")}>
                     <div className={cx("infor")}>
                        <span>Approved Time: </span>
                        <span>
                           <b>{formatDate(p.underwriting_approved_date)}</b>
                        </span>
                     </div>

                     <div className={cx("infor")}>
                        <span>Approved by: </span>
                        <span>
                           <b>{p.underwriting_approved_user.name}</b>
                        </span>
                     </div>
                  </div>
               )}

               <SHrSosButton
                  buttonProps={{
                     className: cx("approveBtn"),
                     onClick: () => mutateUWApproval(),
                     disabled: !!p.underwriting_approved_by || !!p.underwriting_approved_date,
                  }}
                  loading={isPendingUWApproval}
               >
                  Approve
               </SHrSosButton>
            </div>
         </div>
         <div className={cx("purchaseDetailsC")}>
            <span className={cx("heading")}>Purchase details</span>
            <div className={cx("infoC")}>
               <div className={cx("data")}>
                  <PViDataPoint
                     title="Target purchase price"
                     type="bold-value"
                     value={p.target_purchase_price_final}
                     overridden={p.target_purchase_price}
                     format="currency"
                     database={db_P("target_purchase_price_override")}
                     history={{ table: "sos_properties", column: "target_purchase_price_final", record_id: pid }}
                  />
               </div>

               <div className={cx("data")}>
                  <PViDataPoint
                     type={"bold-value"}
                     format="percentage"
                     value={p.arv_uplift}
                     title="ARV uplift"
                     // history={{
                     //    table: "sos_properties",
                     //    column: "arv_uplift",
                     //    record_id: pid,
                     // }}
                  />
               </div>

               {p.underwriting_hcv_applicable && models && (
                  <div className={cx("data")}>
                     {p.underwriting_hcv_applicable && (
                        <PViDataPoint
                           type={"bold-value"}
                           format="percentage"
                           value={p.cap_rate}
                           additionalInfor={"(HCV rent)"}
                           title="Cap rate"
                        />
                     )}

                     {models &&
                        Object.entries(models).map(
                           ([key, model], index) =>
                              model?.applicable && (
                                 <PViDataPoint
                                    type={"bold-value"}
                                    format="percentage"
                                    value={p.underwriting_models_calculated?.[index]?.cap_rate}
                                    additionalInfor={`(${key})`}
                                 />
                              )
                        )}
                  </div>
               )}
               <div className={cx("data")}>
                  <PViDataPoint
                     type={"bold-value"}
                     format="currency"
                     value={p.max_price_arv_uplift}
                     title="Max price"
                     additionalInfor="(ARV uplift)"
                     buttons={[
                        {
                           title: "refresh",
                           onClick: () => mDb_P_dataVerification.mutate({ value: true }),
                           loading: !!mDb_P_dataVerification.isMutating,
                        },
                     ]}
                  />
                  <PViDataPoint
                     type={"bold-value"}
                     additionalInfor="(Cap rate)"
                     format="currency"
                     value={p.max_price_cap_rate}
                  />
               </div>
            </div>
         </div>
         <div className={cx("costC")}>
            <span className={cx("heading")}>Fees & Costs</span>
            <div className={cx("infoC")}>
               <div className={cx("grayBg")}>
                  <PViDataPoint type={"bold-value"} format="currency" value={p.all_in} title="All-in cost" />
               </div>
            </div>

            <hr className={cx("divider")} />

            <div className={cx("infoC")}>
               <div className={cx("grayBg")}>
                  <PViDataPoint
                     type={"bold-value"}
                     format="currency"
                     value={p.estimated_gross_hud}
                     title="Estimated Gross HUD"
                  />
               </div>

               <PViDataPoint
                  title="Contract Price"
                  type="bold-value"
                  value={p.target_purchase_price_final}
                  overridden={p.target_purchase_price}
                  format="currency"
                  database={db_P("target_purchase_price_override")}
                  history={{ table: "sos_properties", column: "target_purchase_price_final", record_id: pid }}
               />

               <PViDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={p.simply_acquisition_fee_final || p.simply_acquisition_fee}
                  overridden={p.simply_acquisition_fee}
                  database={db_P("simply_acquisition_fee_override")}
                  title="Simply Acquisition Fee"
                  history={{
                     table: "sos_properties",
                     column: "simply_acquisition_fee_final",
                     record_id: pid,
                  }}
               />

               <PViDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={p.title_insurance_final | p.title_insurance}
                  overridden={p.title_insurance}
                  database={db_P("title_insurance_override")}
                  title="Title Insurance"
                  history={{
                     table: "sos_properties",
                     column: "title_insurance_final",
                     record_id: pid,
                  }}
               />
               <PViDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={p.transfer_tax_final || p.transfer_tax}
                  overridden={p.transfer_tax}
                  database={db_P("transfer_tax_override")}
                  title="Transfer tax"
                  history={{
                     table: "sos_properties",
                     column: "transfer_tax_final",
                     record_id: pid,
                  }}
               />
               <PViDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={p.closing_cost_final || p.closing_cost}
                  overridden={p.closing_cost}
                  title="Core Closing Costs"
                  database={db_P("closing_cost_override")}
                  history={{
                     table: "sos_properties",
                     column: "closing_cost_final",
                     record_id: pid,
                  }}
               />
            </div>

            <hr className={cx("divider")} />

            <div className={cx("infoC")}>
               <div className={cx("grayBg")}>
                  <PViDataPoint
                     type={"bold-value"}
                     format="currency"
                     value={p.estimated_acquisition_cost_final || p.estimated_acquisition_cost}
                     overridden={p.estimated_acquisition_cost}
                     database={db_P("estimated_acquisition_cost_override")}
                     title="Estimated Acquisition Costs"
                     history={{
                        table: "sos_properties",
                        column: "estimated_acquisition_cost_final",
                        record_id: pid,
                     }}
                  />
               </div>

               <PViDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={p.post_purchase_cost}
                  title="Post-Purchase Cost"
               />

               <PViDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={p.total_owner_reserves_final || p.total_owner_reserves}
                  overridden={p.total_owner_reserves}
                  database={db_P("total_owner_reserves_override")}
                  title="Owner Reserve Amount"
                  history={{
                     table: "sos_properties",
                     column: "total_owner_reserves_final",
                     record_id: pid,
                  }}
               />

               {/* <PViDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={p.additional_ownership_reserve}
                  title="Additional OwnerShip Reserve"
                  database={db_P("additional_ownership_reserve")}
                  history={{
                     table: "sos_properties",
                     column: "additional_ownership_reserve",
                     record_id: pid,
                  }}
               /> */}

               <PViDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={p.pm_reserve_total_final || p.pm_reserve_total}
                  overridden={p.pm_reserve_total}
                  database={db_P("pm_reserve_total_override")}
                  title="PM Reserve Amount"
                  history={{
                     table: "sos_properties",
                     column: "pm_reserve_total_final",
                     record_id: pid,
                  }}
               />
               <PViDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={p.pm_onboarding_total_final || p.pm_onboarding_total}
                  overridden={p.pm_onboarding_total}
                  database={db_P("pm_onboarding_total_override")}
                  title="PM Onboarding Amount"
                  history={{
                     table: "sos_properties",
                     column: "pm_onboarding_total_final",
                     record_id: pid,
                  }}
               />
            </div>

            <hr className={cx("divider")} />

            <div className={cx("infoC")}>
               <PViDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={p.estimated_renovation_cost}
                  title="Estimated Renovation Cost"
                  database={db_P("estimated_renovation_cost")}
                  history={{
                     table: "sos_properties",
                     column: "estimated_renovation_cost",
                     record_id: pid,
                  }}
               />
            </div>
         </div>
         <div className={cx("applicableC")}>
            <span>Applicable Strategies</span>
            <div className={cx("applicableLst")}>
               <SoSCheckbox
                  title={"HCV Rent"}
                  value={p.underwriting_hcv_applicable}
                  onChange={() => mDb_P_underwritingHcvApplicable.mutate({ value: !p.underwriting_hcv_applicable })}
               />

               {models &&
                  Object.entries(models).map(([key, model], index) => (
                     <SoSCheckbox
                        key={index}
                        title={key}
                        value={!!model?.applicable}
                        onChange={() => handleChangeApplicable(index)}
                     />
                  ))}
            </div>
         </div>
         <div className={cx("rentAndHAC")}>
            <div className={cx("tabsAndRecalculateC")}>
               <SoSRoudedTabs
                  tabs={["HCV rent", ...Object.keys(models || {})]}
                  value={activeTab}
                  onChange={(newTab) => {
                     setActiveTab(newTab);
                  }}
                  isCreated
                  onCreate={() => setIsOpenCreateRentTypeModal(true)}
               />

               <div className={cx("modelsBtn")}>
                  {!!selectedModel && selectedModel.name !== "Market Rent" && (
                     <SHrSosButton
                        type="text"
                        buttonProps={{ className: cx("btn", "delete"), onClick: handleDeleteModel }}
                        loading={mDb_P_uwModels.isPending}
                     >
                        <div className={cx("img-icon")}>
                           <img src={CF_CDN_URL("/assets/delete_outline_black.svg")} alt="" />
                        </div>
                        Delete Model
                     </SHrSosButton>
                  )}
                  <SHrSosButton
                     type="text"
                     buttonProps={{
                        className: cx("btn", "recalc"),
                        onClick: () => mDb_P_recalc.mutate({ value: true }),
                     }}
                     loading={!!mDb_P_recalc.isMutating}
                  >
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/property_viability/save_black.svg")} alt="" />
                     </div>
                     Recalculate & Save
                  </SHrSosButton>
               </div>
            </div>

            {activeTab === "HCV rent" ? (
               <PViHCVRentTab />
            ) : !!selectedModel ? (
               <PViCustomRent modelIndex={selectedModel.index} />
            ) : null}
         </div>

         <SHrPopup show={isOpenCreateRentTypeModal}>
            <PViAddNewModelModal onClose={() => setIsOpenCreateRentTypeModal(false)} />
         </SHrPopup>
      </div>
   );
};

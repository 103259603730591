import classNames from "classnames/bind";
import "@cyntler/react-doc-viewer/dist/index.css";
import styles from "./SoSViewerUploadFilesModal.module.scss";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";

const cx = classNames.bind(styles);
export const SoSViewerUploadFilesModal = ({ onClose, uri, fileName }: TSoSUploaderContainerProps) => {
   if (!uri) return null;

   return (
      <div className={cx("container")}>
         <div className={cx("headingC")}>
            <span>View uploaded Files</span>
            <div className={cx("closeBtn")}>
               <button type="button" title="close" onClick={() => onClose()}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>
         <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={[{ uri, fileName }]}
            config={{
               header: {
                  disableHeader: true,
                  disableFileName: true,
                  retainURLParams: true,
               },
            }}
         />
      </div>
   );
};
type TSoSUploaderContainerProps = {
   onClose: () => void;
   uri: string;
   fileName: string;
};

import classNames from "classnames/bind";
import styles from "./TransactionDocumentTab.module.scss";
import { TransactionPropertySwitch } from "../TransactionPropertySwitch/TransactionPropertySwitch";
import { SoSUploaderWrapper } from "../../../components/SoSUploaderWrapper/SoSUploaderWrapper";
import { useMDatabase_UploadFiles } from "../../../hooks/database/mutations/useMDatabase_UploadFiles";
import { useMDatabase_UploadImages } from "../../../hooks/database/mutations/useMDatabase_UploadImages";
import { database_helpers_createPayloadHelper as dbcb } from "../../../hooks/database/mutations/utils";
import { SHrSosButton } from "@simplyhomes/react";
import {
   SoSUploaderContainer,
   SoSUploaderContainerFileType,
   SoSUploaderContainerImageType,
} from "../../../components/common/SoSUploaderContainer/SoSUploaderContainer";
import { useSelectedTransactionAndProperty } from "../../../hooks/transactions/hooks/useSelectedTransactionAndProperty";
import { useEffect, useMemo, useState } from "react";
import { useTransactionsContext } from "../../../contexts/TransactionsContext";
import { useQTrans_Transaction } from "../../../hooks/transactions/queries/useQTrans_Transaction";
import { useMDatabase_RemoveFile } from "../../../hooks/database/mutations/useMDatabase_DeleteFile";

const cx = classNames.bind(styles);

export const TransactionDocumentTab = () => {
   const { transaction, qGetTransaction, pidSelected } = useSelectedTransactionAndProperty();

   // const { pidSelected } = useTransactionsContext().transactionsState;

   // const qGetTransaction = useQTrans_Transaction();

   const [values, setValues] = useState<Record<string, SoSUploaderContainerFileType[]> | null>(null);

   const db_P = dbcb("sos_properties", [{ column: "pid", id: "ChIJnUpq5BDzNIgReLjMZgSeQ3Q" }], () => {
      qGetTransaction?.refetch();
   });
   const mUploadFiles = useMDatabase_UploadFiles(db_P("scope_of_work"));
   const mRemoveFile = useMDatabase_RemoveFile(db_P("scope_of_work"));
   const mUploadImages = useMDatabase_UploadImages(db_P("scope_of_work"));

   useEffect(() => {
      if (!transaction) return;
      const { properties } = transaction;
      const staticFilesTransformed = properties.reduce((acc, p) => {
         acc[p.pid] = p.scope_of_works || [];
         return acc;
      }, {} as Record<string, SoSUploaderContainerFileType[]>);
      setValues(staticFilesTransformed);
   }, [transaction]);

   if (!transaction) return null;

   const { properties } = transaction;

   const handleUploadFiles = (files: FileList) => {
      const newFiles: SoSUploaderContainerFileType[] = Array.from(files).map((file) => ({
         fuid: 0,
         loading: true,
         file_name: file.name,
      }));

      const statisFilesSelected = values?.[properties[0].pid] || [];

      statisFilesSelected.push(...newFiles);

      mUploadFiles.mutate({ files });

      setValues({ ...values, [properties[0].pid]: statisFilesSelected });
   };

   const handleRemoveFile = (fuid: number) => {
      const fileToRemove = values?.[properties[0].pid]?.find((f) => f.fuid === fuid);

      if (!fileToRemove) return;

      fileToRemove.loading = true;

      mRemoveFile.mutate({ fuid });

      setValues({ ...values, [properties[0].pid]: values?.[properties[0].pid]! });
   };

   const handleUploadImages = (files: FileList) => {
      mUploadImages.mutate({ images: files });
   };

   return (
      <div className={cx("container")} style={{ width: "100%" }}>
         document tab
         {/* <TransactionPropertySwitch properties={properties} /> */}
         {/* <SoSUploaderContainer
            type="files"
            values={values?.[properties[0].pid] || []}
            onUpload={(files) => handleUploadFiles(files)}
            onRemove={handleRemoveFile}
         >
            <SHrSosButton type="text" buttonProps={{ className: cx("uploadFileBtn") }} loading={false}>
               Upload a file
            </SHrSosButton>
         </SoSUploaderContainer> */}
      </div>
   );
};
